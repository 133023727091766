<template>
  <div
    class="addplace absolute z-20 top-0 right-0 max-w-xs flex flex-col items-end p-4 font-sans"
    @keydown.esc="onEsc()"
  >
    <button
      class="bg-white border-gray-800 border-opacity-25 padding-box rounded border-2 shadow-lg cursor-pointer hover:bg-yellow-100 px-2 py-1 font-semibold w-24 hidden sm:block"
      @click="toggleAddMode()"
    >
      <span
        class="inline-block transform transition-all duration-200"
        :class="{ 'rotate-45': isAddMode }"
        >+
      </span>
      <span v-show="!isAddMode" class="">Ny plats</span>
      <span v-show="isAddMode" class="">Avbryt</span>
    </button>
    <div
      class="bg-white border-gray-800 border-opacity-25 padding-box rounded border-2 shadow-lg p-4 mt-4"
      v-if="isAddMode"
    >
      <!-- @blur="isAddMode = false" -->
      <p v-if="!newPlace.latlong" class="italic">
        Klicka i kartan för att välja den nya platsens koordinat.
      </p>
      <form
        class="flex flex-col items-end"
        v-if="newPlace.latlong"
        @submit.prevent="onSubmit($event)"
      >
        <input
          class="border mb-2 rounded px-2"
          type="text"
          placeholder="namn"
          v-model="newPlace.namn"
          ref="name"
          @keydown.n.stop
          @keydown.s.stop
        />
        <input
          class="border mb-3 rounded px-2"
          type="text"
          placeholder="adress"
          v-model="newPlace.adress"
          @keydown.n.stop
          @keydown.s.stop
        />
        <div class="text-sm uppercase text-gray-400 self-start mb-1">Region</div>
        <div class="bg-gray-200 rounded-md mb-4 self-start">
          <button
            type="button"
            @click="setSelected(item)"
            class="text-sm hover:bg-gray-600 hover:text-white py-3 px-2 first:rounded-l-md last:rounded-r-md"
            :class="{ 'bg-gray-600 text-white': item.selected }"
            v-for="item in selectableRegions"
            :key="item.label"
          >
            {{ item.label }}
          </button>
        </div>
        <div>
          <span
            class="text-pink-500 font-semibold mr-4 lowercase small-caps tracking inline-block tracking-tighter"
            v-show="successMsg"
            >{{ successMsg }}</span
          >
          <span class="text-red-500 italic mt-4 font-semibold mr-4" v-if="errorMsg">{{
            errorMsg
          }}</span>
          <button
            class="border-gray-800 border-opacity-25 rounded border-2 cursor-pointer bg-yellow-100 px-2 py-1 font-semibold overflow-hidden"
            type="submit"
          >
            Spara
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue"
import EventBus from "../eventbus"
import { postPlace } from "../backend"

let isAddMode = ref(false)
let name = ref(null)
let successMsg = ref("")
let errorMsg = ref("")

let newPlace = ref({})
const emit = defineEmits(["toggle"])
let selectableRegions = ref([
  { label: "[Ingen vald]", val: null, selected: true },
  { label: "Blekinge", val: "Blekinge", selected: false },
  { label: "Halland", val: "Halland", selected: false },
  { label: "Värmland", val: "Värmland", selected: false }
])

EventBus.$on("addMarker", ({ latlng }) => {
  newPlace.value.latlong = latlng
  setTimeout(() => {
    name.value.focus()
  }, 100)
})

function onEsc() {
  isAddMode.value = false
  newPlace.latlong = null
}
function toggleAddMode() {
  isAddMode.value = !isAddMode.value
  emit("toggle")
}

let setSelected = (obj) => {
  for (let item of selectableRegions.value) {
    item.selected = false
  }
  obj.selected = true
  newPlace.value.region = obj.val
}

async function onSubmit() {
  console.log("onSubmit", newPlace.value)
  // this.newPlace["id"] = 765
  try {
    await postPlace({ ...newPlace.value })
    successMsg.value = "Gjort!"
    setTimeout(() => {
      successMsg.value = ""
    }, 3000)
  } catch (err) {
    console.error(err)
    if (err === "Duplicate place.") {
      errorMsg.value = "Platsen finns redan."
    } else {
      errorMsg.value = "Ett fel inträffade i backenden."
    }
    setTimeout(() => {
      errorMsg.value = null
    }, 3000)
  }
}
</script>

<style lang="scss" scoped></style>
