<template>
  <transition name="fade">
    <div
      v-show="(context.placeId || context.libraryId) && !loading"
      class="full w-full absolute z-40 top-0 p-2 sm:pl-6 overflow-auto md:right-0 lg:w-5/12 max-w-lg focus:outline-none rounded"
      ref="main"
      tabindex="5"
      role="dialog"
    >
      <!-- <div class="close-icon" @click="$emit('close')"> -->
      <div class="close-icon z-10" @click="send('ESC')">
        <div class="close-symbol"></div>
      </div>
      <div class="details rounded-lg shadow-custom mb-8">
        <ul>
          <li
            v-for="(detail, i) in filteredDetails || []"
            :key="detail.id"
            :id="detail.id"
            :ref="setItemRef"
          >
            <Article
              :i="i"
              :article="detail"
              :placeObj="placeObj"
              @onMore="toggleMore($event)"
            ></Article>
          </li>
        </ul>
        <div>
          <Library :library="library"></Library>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import * as backend from "../../backend"
import { param, deparam } from "../../util"
import axios from "axios"
import Article from "./Article"
import Library from "./Library"
import once from "lodash/once"
// import TransitionExpand from "./TransitionExpand"

import { onBeforeUpdate, onUpdated, reactive } from "vue"
let detailById = {}

let getImage = async url => {
  if (!url) return
  let response = await axios.get(url, {
    responseType: "blob"
  })
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader()
    fileReader.onerror = () => {
      fileReader.abort()
      reject(new Error("Problem parsing file"))
    }

    fileReader.onloadend = () => {
      resolve([url, fileReader.result])
    }

    fileReader.readAsDataURL(response.data)
  })
}

export default {
  name: "Details",
  props: {
    // place: {
    //   type: Number,
    //   default: null
    // },
    // library: {
    //   type: Number,
    //   default: null
    // }
  },
  components: {
    Article,
    Library
  },

  setup() {
    const setItemRef = el => {
      if (el) {
        detailById[Number(el.id)] = el
      }
    }
    onBeforeUpdate(() => {
      detailById = {}
    })
    // onUpdated(() => {
    //   console.log(detailById)
    // })
    return {
      setItemRef
    }
  },

  data() {
    return {
      loading: false,
      placeObj: null,
      library: null,
      filteredDetails: null
    }
  },
  watch: {
    "context.categories": function(val) {
      if (this.details) {
        this.filteredDetails = this.filterByCategory()
      }
    },
    "context.placeId": {
      async handler(val) {
        console.log("watch place", val)
        if (val) {
          this.details = null
          this.library = null
          await this.getDetails(val)
          this.$refs.main.focus()

          let params = deparam()
          document.title = this.placeObj.namn + " | Litteraturkartan"
          if (params.article) {
            // let placeElem = this.$refs.detail.find(
            //   elem => elem.getAttribute("id") == params.article
            // )
            let placeElem = detailById[params.article]
            let detail = this.details.find(detail => detail.id == Number(params.article))
            this.toggleMore(detail)
            detail.more = true

            // $nextTick should work here but doesn't...
            setTimeout(() => {
              this.$refs.main.scrollTo(0, placeElem.offsetTop)
            }, 100)
          } else {
            this.$refs.main.scrollTop = 0
            window.gtag("event", "page_view", {
              page_title: this.placeObj.namn + " | Litteraturkartan",
              // page_location: "<Page Location>",
              page_path: location.pathname + param({ id: this.placeObj.id }),
              send_to: window.gtagID
            })
          }
          // TODO: test on live site
        } else {
          document.title = "Litteraturkartan"
        }
      }
      // immediate: true
    },
    "context.libraryId": {
      async handler(val) {
        if (val) {
          this.filteredDetails = null
          this.details = null
          this.library = null
          this.loading = true
          this.library = await backend.getLibraryById(val)
          this.library.imgDataUrl = (
            await getImage(this.library.media_file?.data?.thumbnails[5].relative_url)
          )?.[1]
          this.library.imgDataUrl
          this.loading = false
        } else {
          document.title = "Litteraturkartan"
        }
      }
    }
  },
  async created() {},
  methods: {
    toggleMore(article) {
      article.more = !article.more
      if (article.more) {
        document.title = article.header + " | Litteraturkartan"

        setTimeout(() => {
          window.gtag("event", "page_view", {
            page_title: article.header + " | Litteraturkartan",
            // page_location: "<Page Location>",
            page_path: location.pathname + param({ id: this.placeObj.id, article: article.id }),
            send_to: window.gtagID
          })
        }, 100)
      } else {
        document.title = this.placeObj.namn ? this.placeObj.namn + " | " : "Litteraturkartan"
      }
      window.history.replaceState(
        { place: article.placeid.id },
        "Detaljer",
        param({ article: article.more ? article.id : null })
      )
    },
    filterByCategory() {
      if (!this.context.categories) {
        return this.details
      }
      return this.details.filter(detail => this.context.categories.includes(detail.category))
    },

    async getDetails(id) {
      this.loading = true
      let details = await backend.getAllByPlace(id)
      this.placeObj = await backend.getPlace(id)

      let scanForQuotes = text => {
        let output = ""
        let isInTag = false
        for (let char of text) {
          if (char == "<") {
            isInTag = true
          }
          if (isInTag && char == ">") {
            isInTag = false
          }
          if (!isInTag && char == '"') {
            output += "”"
          } else {
            output += char
          }
        }
        return output
      }

      for (let detail of details) {
        detail.more = false
        for (let key of ["free_text", "red_additions"]) {
          if (detail[key]) {
            detail[key] = scanForQuotes(detail[key])
            detail[key] = detail[key].replace(
              "<p><audio>",
              "<p class='audio-container'><audio controls>"
            )
            detail[key] = detail[key].replace(
              '<p><audio data-mce-fragment="1">',
              "<p class='audio-container'><audio controls>"
            )

            detail[key] = detail[key].replace(new RegExp("<p> </p>", "g"), "")
          }
        }
        if ((detail.free_text || "").split("</p>")[0].length < 100) {
          detail.shortP = true
        }
      }
      let getImgUrl = detail => detail.media_file?.data?.thumbnails[5].relative_url || detail.imgurl
      let detailsWithImage = details.filter(getImgUrl)
      let imagePromises = detailsWithImage.map(detail => getImage(getImgUrl(detail)))
      let imageMap = Object.fromEntries(await Promise.all(imagePromises))

      for (let detail of detailsWithImage) {
        detail.imgDataUrl = imageMap[getImgUrl(detail)]
      }
      this.details = reactive(details)
      this.filteredDetails = this.filterByCategory()
      this.loading = false
      this.$emit("load", details)
    }
  },
  computed: {
    // free_text() {
    //   return (this.details.free_text || "").slice(0, 200)
    // },
    // rest_text() {
    //   return (this.details.free_text || "").slice(200)
    // }
  },
  unmounted() {
    // window.removeEventListener("popstate", this.onpopstate)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.details {
  background-color: rgba(255, 255, 255, 0.9);
  // @screen sm {
  //   height: 91vh;
  // }
}

.small-caps {
  font-variant: small-caps;
}

.full {
  /*max-height: calc(100vh - 40px);*/
  max-height: 100vh;
}

.close-symbol {
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
}
.close-symbol:before,
.close-symbol:after {
  position: absolute;
  left: 9px;
  content: " ";
  height: 22px;
  width: 3px;
  background-color: #fff;
}
.close-symbol:before {
  transform: rotate(45deg);
}
.close-symbol:after {
  transform: rotate(-45deg);
}

.close-icon {
  position: fixed;
  top: 15px;
  right: 15px;
  border-radius: 9999px;
  background: #4a4a4a;
  padding: 5px;
  color: white;
  mix-blend-mode: hard-light;
  cursor: pointer;
}

@screen sm {
  .close-icon {
    top: 30px;
    right: 30px;
  }
}

.shadow-custom {
  box-shadow: 0 20px 25px -9px #333;
}
</style>
