<template>
  <div class="absolute z-10 top-0 padding max-w-xs sm:max-w-md w-full mt-3 pr-4">
    <div
      class="main border-2 border-gray-800 border-opacity-25 padding-box sm:mr-0 bg-white -mt-px p-px rounded shadow-xl flex items-center max-w-lg"
    >
      <!-- @keyup.enter="search(context.searchVal)" -->
      <input
        class="p-4 w-full font-sans outline-none placeholder-gray-600"
        role="searchbox"
        aria-autocomplete="list"
        aria-controls="search-results"
        ref="input"
        type="text"
        placeholder="Sökexempel: ”göteborg linné”"
        v-model="context.searchVal"
        @keyup.enter="onEnter()"
        @input="search(context.searchVal)"
        @keydown.down="keyDown()"
        @keydown.up="keyUp()"
        @click="send('SEARCH_INPUT_CLICK')"
        @focus="onInputFocus()"
        @blur="onInputBlur()"
      />

      <font-awesome-icon
        v-show="searching"
        class="color-reddish text-lg mr-5 animate-spin"
        icon="spinner"
        @click="search(context.searchVal)"
      />
      <font-awesome-icon
        v-show="!searching"
        class="color-reddish text-lg mr-5"
        icon="search"
        @click="search(context.searchVal)"
      />
    </div>

    <div class="relative w-full max-w-xs sm:max-w-md" id="search-results">
      <transition name="fade">
        <div
          v-if="context.searchVal && isInit && results.length == 0 && geonames.length == 0"
          class="font-sans mt-1 border-2 rounded shadow-xl max-w-lg p-3 bg-yellow-100 cursor-pointer absolute top-0 left-0 w-full"
        >
          Inga träffar
        </div>
      </transition>
      <transition name="fade">
        <div
          v-if="context.showNearMe"
          role="button"
          class="font-sans mt-1 border-2 rounded shadow-xl max-w-lg p-3 bg-yellow-100 cursor-pointer absolute top-0 left-0 w-full"
          @click="searchNearMe()"
        >
          <font-awesome-icon class="text-gray-700" icon="crosshairs" /> Sök nära dig
        </div>
      </transition>

      <transition name="fade">
        <div
          class="results bg-white font-sans mt-1 border-2 rounded result-height overflow-auto shadow-xl max-w-lg absolute top-0 left-0 w-full"
          ref="menu"
          v-show="context.showSearchResults"
        >
          <ul class="search-results divide-y divide-gray-400">
            <li
              @click="
                $emit('select', {
                  placeid: result.placeid,
                  articleid: result.id,
                  libraryid: result.id
                })
              "
              class="p-3 hover:bg-yellow-100 cursor-pointer"
              :class="{
                'bg-yellow-100': i == enabledIndex,
                'text-gray-400': result.source == 'litteraturkartan' && !result.placeid
              }"
              v-for="(result, i) in results"
              :ref="setItemRef"
              role="listitem"
              :key="i"
            >
              <div class="flex justify-between">
                <highlighter
                  v-if="result.source == 'litteraturkartan'"
                  class=""
                  field="header"
                  :highlight="result.highlight"
                  :fallback="result.header"
                ></highlighter>

                <span class="" v-if="result.source == 'litteraturkartan_library'">
                  <svg
                    class="w-6 h-6 inline mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
                    ></path>
                  </svg>
                  <highlighter
                    class="inline-block align-middle"
                    field="name"
                    :highlight="result.highlight"
                    :fallback="result.name"
                  ></highlighter>
                </span>

                <span
                  v-if="$isDev && result.status == 'draft'"
                  class="bg-indigo-600 text-indigo-100 rounded self-end px-2 -mr-2"
                  >Utkast</span
                >
              </div>
              <div class="text-sm">
                <highlighter
                  field="free_text"
                  :highlight="result.highlight"
                  :fallback="''"
                ></highlighter>
              </div>

              <div class="uppercase text-gray-700 text-sm">
                <highlighter
                  class=""
                  field="placename"
                  :highlight="result.highlight"
                  :fallback="result.placename"
                ></highlighter>
              </div>
            </li>

            <li
              role="heading"
              class="p-3 pb-0 text-sm uppercase text-gray-500"
              v-if="geonames && geonames.length"
            >
              Sök i närheten av
            </li>
            <li
              v-for="(geoname, i) in geonames"
              class="p-3 hover:bg-yellow-100 cursor-pointer flex items-center"
              style="border-top: none"
              :class="{ 'bg-yellow-100': i == enabledIndex - results.length }"
              :key="geoname.geonameid"
              :ref="setItemRef"
              role="listitem"
              @click="searchNear(geoname.coord)"
            >
              <svg
                class="w-5 h-5 inline mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                ></path>
              </svg>
              {{ geoname.name }}, {{ geoname.municipality }}
              <span class="ml-1" v-if="geoname.nearHits"> ({{ geoname.nearHits }})</span>
            </li>
            <li
              @click="search(context.searchVal, true)"
              ref="seeMore"
              class="p-3 hover:bg-yellow-100 cursor-pointer flex items-center"
              v-if="results.length < hits"
              :class="{ 'bg-yellow-100': enabledIndex == geonames.length + results.length }"
            >
              <font-awesome-icon class="color-reddish text-lg mr-3" icon="plus" />
              <a class="font-semibold color-reddish">Visa alla {{ hits }} träffar</a>
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import * as backend from "../backend"
import debounce from "lodash/debounce"
import throttle from "lodash/throttle"
import EventBus from "../eventbus"
import Highlighter from "./Highlighter.vue"
import { deparam, param } from "../util"
import { onBeforeUpdate } from "vue"

// let transitionListener = state => console.log("search state", state, state.value)
let focusable = []
export default {
  components: { Highlighter },
  name: "Search",

  setup() {
    const setItemRef = (el) => {
      if (el) {
        focusable.push(el)
      }
    }
    onBeforeUpdate(() => {
      focusable = []
    })
    // onUpdated(() => {
    //   console.log(detailById)
    // })
    return {
      setItemRef
    }
  },

  data() {
    return {
      isInit: false,
      results: [],
      geonames: [],
      hits: 0,
      enabledIndex: 0,
      isInputFocused: false,
      searching: false
    }
  },
  created() {
    // this.service.onTransition(transitionListener)
    EventBus.$off("focusSearch")
    EventBus.$on("focusSearch", () => {
      let { input } = this.$refs
      if (input.matches(":focus")) {
        return
      }
      setTimeout(() => {
        input.focus()
        input.setSelectionRange(0, input.value.length)
      }, 300)
    })

    EventBus.$on("refreshSearch", () => {
      this.search(this.context.searchVal)
    })
  },
  unmounted() {},
  mounted() {
    if (deparam().s) {
      this.send("SEARCH_INIT")
    }
  },
  computed: {
    // isInputFocused() {
    //   if (!this.$refs.input) return false
    //   return this.$refs.input.matches(":focus")
    // }
  },
  methods: {
    saveSearch: debounce((searchstr) => {
      let p = param({ s: searchstr })
      window.history.replaceState({ s: searchstr }, "Detaljer", p)
      if (searchstr.length > 2) {
        window.gtag("event", "page_view", {
          page_title: "Sökning | Litteraturkartan",
          // page_location: "<Page Location>",
          page_path: location.pathname + p,
          send_to: window.gtagID
        })
      }
    }, 500),
    onInputFocus() {
      // this.send("SEARCH_FOCUS")
      this.isInputFocused = true
    },
    onInputBlur() {
      // this.send("SEARCH_BLUR")
      this.isInputFocused = false
    },
    async searchNearMe() {
      this.searching = true
      this.geonames = []
      let promise = new Promise((resolve, reject) => {
        let timeout = setTimeout(() => {
          reject(new Error("Timeout"))
        }, 1000)
        navigator.geolocation.getCurrentPosition(
          async (pos) => {
            clearTimeout(timeout)
            this.results = await backend.searchNear({
              lat: pos.coords.latitude,
              lon: pos.coords.longitude
            })
            this.send("NEAR_ME_RESULTS")
            resolve()
          },
          (err) => {
            console.warn(`Geolocation error (${err.code}): ${err.message}`)
            reject(err)
          }
        )
      })
      try {
        await promise
      } catch (error) {
        console.log("Geolocation error:", error.message)
      } finally {
        this.searching = false
        this.isInit = true
      }
    },
    onEnter() {
      if (this.context.showNearMe) {
        this.searchNearMe()
        return
      }
      if (this.getActiveElem() == this.$refs.seeMore) {
        this.search(this.context.searchVal, true)
        return
      }
      let target = [...this.results, ...(this.geonames || [])][this.enabledIndex]
      if (this.enabledIndex < this.results.length) {
        this.$emit("select", {
          placeid: target.placeid,
          articleid: target.id
        })
      } else {
        this.searchNear(target.coord)
      }
    },
    async searchNear(coord) {
      this.send({ type: "SEARCH_NEAR", coord: { lat: coord.lat, lng: coord.lon }, zoom: 12 })
      this.results = await backend.searchNear(coord)
      this.isInit = true
      this.geonames = []
      this.enabledIndex = 0
      this.$nextTick(() => (this.$refs.menu.scrollTop = 0))
      this.$refs.input.focus()
      // hack to set caret at end of input
      this.context.searchVal = this.context.searchVal
    },
    keyUp() {
      if (!this.results.length && !this.geonames.length) return
      let endIndex = this.results.length + this.geonames.length - 1
      let maybeSeeMore = this.results.length < this.hits
      if (maybeSeeMore) endIndex++

      if (this.enabledIndex > 0) this.enabledIndex--
      else {
        this.enabledIndex = endIndex
      }
      if (!this.checkInView(this.$refs.menu, this.getActiveElem(), true)) {
        setTimeout(
          () =>
            this.getActiveElem().scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest"
            }),
          10
        )
      }
    },
    keyDown() {
      if (!this.results.length && !this.geonames.length) return
      let endIndex = this.results.length + this.geonames.length - 1
      let maybeSeeMore = this.results.length < this.hits
      if (maybeSeeMore) endIndex++
      if (this.enabledIndex + 1 <= endIndex) this.enabledIndex++
      else {
        this.enabledIndex = 0
      }
      if (!this.checkInView(this.$refs.menu, this.getActiveElem(), true)) {
        setTimeout(() => this.getActiveElem().scrollIntoView({ behavior: "smooth" }), 10)
      }
    },
    checkInView(container, element, partial) {
      // debugger
      //Get container properties
      let cTop = container.scrollTop
      let cBottom = cTop + container.clientHeight

      //Get element properties
      let eTop = element.offsetTop
      let eBottom = eTop + element.clientHeight

      //Check if in view
      let isTotal = eTop >= cTop && eBottom <= cBottom
      let isPartial =
        partial && ((eTop < cTop && eBottom > cTop) || (eBottom > cBottom && eTop < cBottom))

      //Return outcome
      return isTotal || isPartial
    },
    getActiveElem() {
      let maybeSeeMore = this.results.length < this.hits

      // let elems = [...(this.$refs.items || []), ...(this.$refs.geonames || [])]
      let elems = [...focusable]
      if (maybeSeeMore) elems.push(this.$refs.seeMore)
      return elems[this.enabledIndex]
    },
    search: debounce(async function (search_str, getAll = false) {
      this.send({ type: "SEARCH_INPUT", search_str })
      if (!search_str) {
        this.results = []
        this.geonames = []
        window.history.replaceState({}, "", window.location.pathname)
        return
      }
      this.saveSearch(search_str)
      let data, hits
      ;[{ data, hits }, this.geonames] = await Promise.all([
        backend.lbSearch(search_str.toLowerCase(), this.context.mapConstraints, getAll),
        backend.searchGeonames(search_str.toLowerCase())
      ])
      this.results = data
      this.hits = hits
      console.log("🚀 ~ file: Search.vue ~ line 335 ~ hits", this.results, hits)
      this.send("SEARCH_RESULTS")
      this.isInit = true
      if (!getAll) {
        this.$nextTick(() => {
          if (!this.$refs.menu) return
          this.$refs.menu.scrollTop = 0
        })
        this.enabledIndex = 0
      }
    }, 200)
  }
}
</script>

<style lang="scss" scoped>
.padding {
  left: 3.5rem;
  // padding-right: 4.5rem;
}

.result-height {
  max-height: calc(100vh - 250px);
}
input::-webkit-input-placeholder {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}
</style>
